import axios from 'axios';

export default class ExamViewResource {
  comments(examScheduleId) {
    return axios.get('/api/view-exams/comments/' + examScheduleId);
  }

  answers(unitId, examScheduleId, examNumberId) {
    return axios.get('/api/view-exams/answers/' + unitId + '/' + examScheduleId + '/' + examNumberId);
  }

  answerBlanks(unitId, examScheduleId, examNumberId) {
    return axios.get('/api/view-exams/answer-blanks/' + unitId + '/' + examScheduleId + '/' + examNumberId);
  }


  ticketWithStudentAnswers(unitID,schedule_examID,exam_numberID){


    return axios.post('/api/view-exams/ticketWithStudentsAnswers',{unitID,schedule_examID,exam_numberID})


  }



}
