<template>

 


  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <div class="row">
          <div class="col-12 ">
            <app-header></app-header>
            <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
          </div>
        </div>


        <!-- Exam View Details section -->
        <section class="exam-view-details-section  px-0 mx-0 px-xl-5 mx-xl-5 mt-lg-2 mb-3">
          <div class="container-fluid  h-100">
            <div class="row align-items-center mt-5 h-100">

              <div class="col-12">
                <div class="test-wrapper bg-light b-30">
                  <div v-if="examDetailsData.length == 0" class="test-container">
                    <div class="heading heading-secondary text-center mb-3 mb-sm-4">
                      <span class="f-heading-bold">{{ $t('exam-view.details.questions-not-found') }}</span>
                    </div>
                  </div>
                  <div class="test-container">
                    <div v-if="examDetailsData.length > 0" class="questions-container">
                      <!-- test questions  -->
                      <!-- <div class="heading heading-secondary text-center mb-3 mb-sm-4">
                        <span class="f-heading-bold">{{ $t('exam-view.details.multiple-choice-questions') }}</span>
                      </div> -->
                      <!-- <div class="heading heading-secondary mb-3 mb-sm-4">
                        <span class="text-danger f-heading-bold">{{ $t('exam-view.details.total-points') }}: {{ answers.total }}</span>
                      </div> -->
                        <h1 class="text-center"> {{ $t('exam-view.details.finalPoint') }} {{ Number(finalSum).toFixed(1)  }}</h1>
                      <div class="answer mb-4" v-for="(data, $index) of examDetailsData">
                        <div class="test-question">
                          <div class="question-text">
                            {{ $t('exam-view.details.question') }} N{{ $index + 1 }}

                            

                            <span v-html="data.question"></span>

                          </div>
                          <div class="answer-text">
                       
                            {{ $t('exam-view.details.answer') }}: <br> <span v-html="data.answer?data.answer:Object.keys(data.answer_blank[0].answer).length==0?'':data.answer_blank[0].answer"></span>

 

                            <p v-if="file.link!='#'" v-for="(file, $index) of data.file_urls">
                              <a @click="downloadFile(file)"
                                 href="#">{{ $t('exam-view.details.uploaded-file') }} N{{ $index + 1 }}</a>
                            </p>



                          </div>
                        </div>
                        
                        <div class="score">{{ $t('exam-view.details.point') }}: <span >{{ data.answer?Number(data.each_point):Object.keys(data.answer_blank[0].answer).length==0?'':data.answer_blank[0].isChecked>0?Number(data.answer_blank[0].each_point):$t('exam-view.details.notChecked') }}</span></div>
                        <div class="comment" v-if="data.comment">
                          
                          <span>{{ $t('exam-view.details.teacher-comment') }}: </span>
                          {{ data.comment }}
                        </div>
                      
                      </div>
                    </div>


<!-- 
                    <div v-if="answerBlanks && answerBlanks.length > 0" class="open-questions-container">
                 
                      <div class="heading heading-secondary text-center">
                        <span class="f-heading-bold">{{ $t('exam-view.details.open-questions') }}</span>
                      </div>

                      <div v-for="answer of answerBlanks" class="answer">
                        <div v-if="answer.answer_blank" class="open-ended-question" v-html="answer.answer_blank"></div>
                        <div v-if="answer.file_urls.length > 0">

                          <div class="score">
                            <p v-for="(file, $index) of answer.file_urls">
                              <a @click="downloadFile(file)"
                                 href="#">{{ $t('exam-view.details.uploaded-file') }} N{{ $index + 1 }}</a>
                            </p>
                          </div>
                        </div>
                        <div class="score">{{ $t('exam-view.details.point') }}: {{ answer.each_point }}</div>
                        <div class="comment" v-if="comments && getCommentByQuestionId(answer.questionID)">
                          <span>{{ $t('exam-view.details.teacher-comment') }}: </span>
                          {{ getCommentByQuestionId(answer.questionID).comment }}
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>


      </main>
      <!-- MAIN CONTETN end -->
    </div>
  </div>

</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import { mapGetters } from 'vuex';
  import ExamViewResource from '../api/examViewResource';
  import axios from 'axios';

  const examViewResource = new ExamViewResource();

  export default {
    name: 'exam-detail-view',
    components: { sidebar, appHeader, pageHeader },

    data() {
      return {
        comments: '',
        answers: {
          total: 0,
          answers: []
        },
        answerBlanks: [],
        examDetailsData:[],
        examType:'test',
        finalSum:0
      }
    },

    computed: {
      pageData() {
        return {
          title: this.$t('exam-view.details.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('exam-view.title'),
              link: '/exam-view',
              isActive: false,
            }],
        };
      },
      ...mapGetters({
        exams: 'exam/exams',
      })
    },

    methods: {
      getCommentByQuestionId(questionId) {
        return this.comments.find(o => o.questionID == questionId);
      },

      downloadFile(file) {
        axios({
          url: file.link,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      },

      loadComment() {
        examViewResource.comments(this.$route.params.examScheduleID)
          .then((response) => {
            this.comments = response.data.data;
          })
      },

      loadAnswers() {
        examViewResource.answers(this.$route.params.id, this.$route.params.examScheduleID, this.$route.params.examNumberId)
          .then((response) => {
            this.answers = response.data.data;
          })
      },

      loadBlankAnswers() {
        examViewResource.answerBlanks(this.$route.params.id, this.$route.params.examScheduleID, this.$route.params.examNumberId)
          .then((response) => {
            this.answerBlanks = response.data.data;
            
            
            

          })
      },


      ticketWithStudentAnswers(){

        examViewResource.ticketWithStudentAnswers(this.$route.params.id, this.$route.params.examScheduleID, this.$route.params.examNumberId)
          .then((response) => { 

            let tempArray=[]

                          let question=''
                          let answer=''
                          let each_point=0
                          let comment=''
                          let questionID=0
                          let isChecked=0


            for(let i in response.data){

               if(response.data[i].question_typeID!=5 && response.data[i].question_typeID!=7){

                  if(response.data[i-1]){

                        if(response.data[i].questionID==response.data[i-1].questionID){

                              

                              tempArray.find(o=>o.questionID==response.data[i-1].questionID).answer+='<br><br>'+response.data[i].answer
                              tempArray.find(o=>o.questionID==response.data[i-1].questionID).question=response.data[i].question
                              tempArray.find(o=>o.questionID==response.data[i-1].questionID).each_point=Number(response.data[i].each_point)+Number(response.data[i-1].each_point)
                              tempArray.find(o=>o.questionID==response.data[i-1].questionID).comment=response.data[i].comment
                              tempArray.find(o=>o.questionID==response.data[i-1].questionID).questionID=response.data[i].questionID
                              tempArray.find(o=>o.questionID==response.data[i-1].questionID).isChecked=response.data[i].isChecked
                          

                          

                          
                             

                        } else {
                          question=response.data[i].question
                            answer=response.data[i].answer
                            each_point=response.data[i].each_point
                            comment=response.data[i].comment
                            questionID=response.data[i].questionID
                            isChecked=response.data[i].isChecked

                            tempArray.push({
                              question:question,
                              answer:answer,
                              each_point:each_point,
                              comment:comment,
                              questionID:questionID,
                              isChecked:isChecked


                            })

                        }

                  }else {


                   
                          question=response.data[i].question
                            answer=response.data[i].answer
                            each_point=response.data[i].each_point
                            comment=response.data[i].comment
                            questionID=response.data[i].questionID
                            isChecked=response.data[i].isChecked

                            tempArray.push({
                              question:question,
                              answer:answer,
                              each_point:each_point,
                              comment:comment,
                              questionID:questionID,
                              isChecked:isChecked


                            })
 


                  } 

                }else {

                    tempArray.push(response.data[i])


                }



            }
  
            this.examDetailsData =tempArray;

            let sum=0

            for(let data of tempArray){

              if(data.answer){
                sum+=Number(data.each_point)
              }else if (Object.keys(data.answer_blank[0].answer).length==0){
                sum+=0
              }else {

                sum+=Number(data.answer_blank[0].each_point)

              }

               


            }

            this.finalSum=sum
            
             
          })



      }


    },

    mounted() {
      this.loadComment();
      this.loadAnswers();
      this.loadBlankAnswers();
      this.ticketWithStudentAnswers()
    }
  };
</script>
